import React from "react";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import GatsbyLink from "gatsby-link";
import PageWrapper from "components/PageWrapper";
import log from "utils/log";
import DogCard from "components/DogCard";
import SolidCircularButton from "components/SolidCircularButton";
import * as coreTheme from "@bit/cpinnix.verious.theme";
import * as heroClasses from "./hero.module.css";

export const query = graphql`
  query {
    descriptionImage: contentfulAsset(
      contentful_id: { eq: "4MO4JDc5UBW9bUV9oR5DaR" }
    ) {
      id
      fixed {
        src
      }
    }
    heroImage: contentfulAsset(
      contentful_id: { eq: "4c1esjRhGTxzT6E2CQDmvB" }
    ) {
      id
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    testimonials: allContentfulHappyTail(limit: 3) {
      edges {
        node {
          id
          description {
            raw
          }
          name
          author
          headline
          teaser
          teaserImage {
            fluid(maxHeight: 480, maxWidth: 480, resizingBehavior: THUMB) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    dogs: allContentfulAdoptDog {
      edges {
        node {
          id
          name
          sex
          weight
          breed
          adopted
          age
          adoptionLink
          picture {
            fluid(maxHeight: 640, maxWidth: 480, resizingBehavior: THUMB) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

function present(data) {
  const seo = {
    title: "Home",
    image: data?.descriptionImage?.fixed?.src,
    description:
      "Nana's Haven in St. Petersburg, FL offers support and services for neglected and abused animals. Learn more about us today.",
  };

  const presentation = {
    seo,
    testimonials: data?.testimonials?.edges.map((edge) => edge.node),
    adoptedDogs: data?.dogs?.edges
      .map((edge) => edge.node)
      .filter(({ adopted }) => adopted),
    heroImage: data?.heroImage?.fluid,
  };

  log("[PAGE] presentation", presentation);

  return presentation;
}

export default function Page({ data }) {
  const { testimonials, adoptedDogs, seo, heroImage } = present(data);

  return (
    <PageWrapper seo={seo}>
      {/* Hero ---> */}
      <div className="container mx-auto px-8 md:px-16">
        <div className={heroClasses.heroLayout}>
          <div className={heroClasses.heroText}>
            <div className={heroClasses.heroTextLayout}>
              <h1 className={heroClasses.heroTitle}>Happy Tails</h1>
            </div>
          </div>
          <div className={heroClasses.heroImage}>
            <GatsbyImage fluid={heroImage} />
          </div>
        </div>
      </div>
      {/* <---- Hero */}

      <div className="bg-yellow-100 md:py-32">
        <div className="container mx-auto md:px-16">
          {testimonials.map((testimonial) => {
            return (
              <div className="grid gap-8 md:grid-cols-12 bg-white md:mb-8">
                <div className="md:col-span-6">
                  <GatsbyLink to={`/happy-tail/${testimonial.id}`}>
                    <GatsbyImage fluid={testimonial.teaserImage.fluid} />
                  </GatsbyLink>
                </div>
                <div
                  className="
                        pl-8
                        pt-6
                        pr-8
                        pb-6
                        sm:col-span-8
                        md:pl-0
                        md:col-span-6
                        md:pt-16
                        lg:col-start-8
                        lg:col-span-4
                        lg:pt-24
                        xl:pt-48
                      "
                >
                  <h2
                    className="text-2xl mb-2"
                    style={{ fontFamily: "Source Serif Pro" }}
                  >
                    {testimonial.name}
                  </h2>
                  <p className="mb-4">{testimonial.teaser}</p>
                  <GatsbyLink to={`/happy-tail/${testimonial.id}`}>
                    <SolidCircularButton>Read More</SolidCircularButton>
                  </GatsbyLink>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="bg-pink-200 py-32">
        <div className="container mx-auto sm:px-8 md:px-16">
          <h1
            className="px-8 md:px-0 text-3xl text-pink-900 font-bold mb-8"
            style={{ fontFamily: "Source Serif Pro" }}
          >
            Adopted Dogs
          </h1>
          <div className="sm:grid sm:gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {adoptedDogs.map((dog, index) => (
              <div
                key={index}
                className="md:col-span-1 lg:col-span-1 xl:col-span-1"
              >
                <DogCard
                  key={dog.id}
                  Image={
                    <GatsbyLink to={`/dog/${dog.id}`}>
                      <GatsbyImage fluid={dog.picture[0].fluid} />
                    </GatsbyLink>
                  }
                  LearnMoreCallToAction={
                    <GatsbyLink to={`/dog/${dog.id}`}>
                      <SolidCircularButton>Learn More</SolidCircularButton>
                    </GatsbyLink>
                  }
                  name={dog.name}
                  sex={dog.sex}
                  age={dog.age}
                  adopted={dog.adopted}
                  theme={{
                    wrapper: {
                      backgroundColor: coreTheme.white,
                    },
                    adopted: {
                      color: coreTheme.white,
                      backgroundColor: "#f27d77",
                    },
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
